import React, { useState } from "react";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";

import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CategoryIcon from "@mui/icons-material/Category";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import VerifiedIcon from "@mui/icons-material/Verified";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BoltIcon from "@mui/icons-material/Bolt";
import CancelIcon from "@mui/icons-material/Cancel";

import { Helmet } from "react-helmet";

import { useNavigate } from "react-router-dom";
import { SuperSEO } from "react-super-seo";

/* import { Document, Page } from "react-pdf/dist/esm/entry.webpack"; */

import { Modal } from "react-bootstrap";

import sample from "../../assets/psd1.pdf";
import PSDView from "./components/PSDView";

import "./singleproductcss.css";
import PSDthumb from "./components/PSDthumb";
import PSDViewMobile from "./components/PSDViewMobile";
import { COLORS } from "../../assets/COLORS";
import LoginModal from "../login/LoginModal";
const SingleProduct = () => {
  const [thumbshow, setThumbShow] = useState(false);

  const [show, setShow] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleThumbClose = () => setThumbShow(false);

  const navigate = useNavigate();

  return (
    <div style={{ padding: 10 }}>
      <>
        <SuperSEO
          title="SEO | React Super SEO"
          description="React SEO component with OpenGraph and Twitter Cards support."
          lang="en"
          openGraph={{
            ogImage: {
              ogImage: "http://placekitten.com/1200/630",
              ogImageAlt: "Kittens",
              ogImageWidth: 1200,
              ogImageHeight: 630,
              ogImageType: "image/jpeg",
            },
          }}
          twitter={{
            twitterSummaryCard: {
              summaryCardImage: "http://placekitten.com/1200/630",
              summaryCardImageAlt: "Kittens",
              summaryCardSiteUsername: "justinmahar",
            },
          }}
        />

        {loginPopup && <LoginModal setLoginPopup={setLoginPopup} />}

        <PSDView
          handleShow={handleShow}
          handleClose={handleClose}
          show={show}
          height="300"
          width="700"
        />
      </>
      <>
        <PSDViewMobile
          handleShow={handleShow}
          handleThumbClose={handleThumbClose}
          thumbshow={thumbshow}
          height="400"
          width="350"
        />
      </>

      {/*    <PSDthumb /> */}
      <div>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6} lg={8}>
            <div>
              <img
                src="https://i0.wp.com/freeweddingpsd.com/wp-content/uploads/2020/wb/12x36_-(854).webp?ssl=1"
                alt=""
                width="100%"
                height="auto"
                style={{
                  cursor: "zoom-in",
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div
              style={{
                backgroundColor: "white",
                padding: 10,
                borderRadius: 20,
              }}
            >
              <div>
                <h4 style={{ color: "#9b2226" }}>
                  {" "}
                  Telugu Wedding PSD Pack 12x36
                </h4>
              </div>
              <div>
                <h3 style={{ color: "#0077b6" }}> Rs. 299 /-</h3>
                <s style={{ color: "grey" }}>
                  <h5 style={{ color: "grey" }}> Rs. 499 /-</h5>
                </s>
              </div>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                <div style={{ marginBottom: 15, marginTop: 15 }}>
                  <Button
                    variant="contained"
                    style={{
                      background: COLORS.secondary,
                      width: "100%",
                      height: 50,
                    }}
                    startIcon={<VisibilityIcon />}
                    onClick={handleShow}
                  >
                    FullScreen Preview
                  </Button>
                </div>
              </Box>

              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <div
                  style={{
                    marginBottom: 15,
                    marginTop: 15,
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      background: COLORS.secondary,
                      width: "100%",
                      height: 50,
                    }}
                    startIcon={<VisibilityIcon />}
                    onClick={() => setThumbShow(true)}
                  >
                    Thumbnails Preview
                  </Button>
                </div>
              </Box>

              <div style={{ marginBottom: 15, marginTop: 15 }}>
                <Button
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  style={{
                    background: COLORS.fifth,
                    width: "100%",
                    height: 50,
                  }}
                  onClick={() => setLoginPopup(true)}
                >
                  Download
                </Button>

                <div style={{ display: "flex", marginTop: 10 }}>
                  <BoltIcon style={{ color: "orange" }} />
                  <span style={{ color: "orange" }}>Instant Download</span>
                </div>
              </div>

              {/*  <div style={{ height: 100, width: 100 }}>
                <img
                  src="https://1fid.com/wp-content/uploads/2022/06/Twitter-profile-picture-1024x1022.jpg"
                  width={"auto"}
                  height="100%"
                />
              </div> */}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} md={6} lg={8}>
            <div style={{ background: "white", padding: 20, borderRadius: 20 }}>
              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div>
                    <h4 style={{ color: "grey" }}>Image Specs</h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <InsertPhotoIcon />
                      <span style={{ marginLeft: 5 }}>Image ID: 487585</span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <ZoomOutMapIcon />
                      <span style={{ marginLeft: 5 }}>Image Size : 12X36</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <AccessTimeIcon />
                      <span style={{ marginLeft: 5 }}>
                        Created : Jul 25, 2022
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <CategoryIcon />
                      <span style={{ marginLeft: 5 }}>Category : PSD</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <ViewInArIcon />
                      <span style={{ marginLeft: 5 }}>File Size : 2GB</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <PlagiarismIcon />
                      <span style={{ marginLeft: 5 }}>File Type : PNG/PSD</span>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div>
                    <h4 style={{ color: "grey" }}>Download Details</h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <InsertPhotoIcon />
                      <span style={{ marginLeft: 5 }}>
                        Download Type : Instant
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <AccessTimeIcon />
                      <span style={{ marginLeft: 5 }}>
                        Link Validity : 5 Days
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <CategoryIcon />
                      <span style={{ marginLeft: 5 }}>
                        Waiting Time : Instant
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div style={{ background: "white", padding: 20, borderRadius: 20 }}>
              <span style={{ color: "grey" }}>Sold by</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "start",
                  marginTop: 20,
                }}
              >
                <div style={{ height: 100, width: 100, marginRight: 12 }}>
                  <img
                    src="https://1fid.com/wp-content/uploads/2022/06/Twitter-profile-picture-1024x1022.jpg"
                    width={"auto"}
                    height="100%"
                    style={{ borderRadius: 50 }}
                  />
                </div>

                <div>
                  <div style={{ display: "flex" }}>
                    <h4>ALBUM RAJA</h4>
                    <VerifiedIcon style={{ color: "#5FAA74" }} />
                  </div>

                  <span>Posts : 23</span>
                  <br />
                  {/*      <span>Follwers : 231</span> */}
                  <div>
                    {/*    <Button variant="outlined" size="small">
                      + Follow
                    </Button> */}
                    <Button variant="contained" size="small">
                      Visit Store
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SingleProduct;
