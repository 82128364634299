import React from "react";
import { Button, Box, Grid } from "@mui/material";
import OrderSidebar from "./components/OrderSidebar";
import { useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import { COLORS } from "../../../assets/COLORS";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";

function OrderDetailPage() {
  const navigate = useNavigate();
  return (
    <div style={{ padding: 10 }}>
      <Grid container spacing={2} style={{ marginBottom: 50 }}>
        <Grid item xs={12} sm={12} md={12} lg={3} spacing={2}>
          <OrderSidebar />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={9} spacing={2}>
          <div
            style={{
              background: "white",
              padding: 10,
              borderRadius: 20,
            }}
          >
            <div style={{ marginBottom: 25 }}>
              <Button
                variant="contained"
                onClick={() => navigate(-1)}
                size="small"
                style={{ background: COLORS.primary }}
              >
                Back
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <h5>Order No : P-4525</h5>
              <h5>Status : ORDER PLACED</h5>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Date : 12/11/2022, 08:52 PM</span>
              <div
                style={{
                  background: COLORS.secondary,
                  width: 100,
                  height: 30,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ color: COLORS.white, fontWeight: "bold" }}>
                  PAID
                </span>
              </div>
            </div>

            <Grid container spacing={0} style={{ marginTop: 10 }}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div>
                  <div>
                    <span>To</span>
                  </div>
                  <div>
                    <span>Srinivas</span>
                  </div>
                  <div>
                    <span>9701439696</span>
                  </div>
                  <div>
                    <span>srinivasn@gmail.com</span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div>
                  <div>
                    <span>From</span>
                  </div>
                  <div>
                    <span>albumraja.com</span>
                  </div>

                  <div>
                    <span>albumraja@gmail.com</span>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div style={{ marginTop: 35 }}>
              <div>
                <h5 style={{ color: COLORS.third }}>Order item details</h5>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead
                    style={{
                      background: COLORS.lightgrey,
                      color: COLORS.white,
                      height: 40,
                    }}
                  >
                    <td>Item Code</td>
                    <td>Item Name</td>
                    <td>File Type</td>
                    <td>Price</td>
                  </thead>
                  <tbody>
                    <tr>
                      <td>125</td>
                      <td>Telugu South style wedding psd</td>
                      <td>PSD</td>
                      <td>250</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ marginTop: 35 }}>
              <div>
                <h5 style={{ color: COLORS.third }}>Files</h5>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead
                    style={{
                      background: COLORS.lightgrey,
                      color: COLORS.white,
                      height: 40,
                    }}
                  >
                    <td>Filename</td>
                    <td>Expire Date</td>
                    <td>Status</td>
                    <td>Action</td>
                  </thead>
                  <tbody>
                    <tr>
                      <td>wd-121-1.zip</td>
                      <td>15/11/2022, 10:12 AM</td>
                      <td>Active</td>
                      <td>
                        <Button
                          variant="contained"
                          size="small"
                          style={{ background: COLORS.fifth }}
                        >
                          Download
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>wd-121-1.zip</td>
                      <td>15/11/2022, 10:12 AM</td>
                      <td>Active</td>
                      <td>
                        <Button
                          variant="contained"
                          size="small"
                          style={{ background: COLORS.fifth }}
                        >
                          Download
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <span style={{ color: COLORS.fifth }}>
                  Note: Please download files before expire date.
                </span>
              </div>

              {/*      <Grid
                container
                spacing={0}
                style={{
                  marginTop: 10,
                  background: COLORS.darkblue,
                  borderRadius: 10,
                  padding: 10,
                }}
              >
                <Grid item xs={12} sm={6} md={6} lg={5}>
                  <div>
                    <div>
                      <StarOutlineOutlinedIcon
                        style={{ fontSize: 30, color: COLORS.darkyellow }}
                      />
                      <StarOutlineOutlinedIcon
                        style={{ fontSize: 30, color: COLORS.darkyellow }}
                      />
                      <StarOutlineOutlinedIcon
                        style={{ fontSize: 30, color: COLORS.darkyellow }}
                      />
                      <StarOutlineOutlinedIcon
                        style={{ fontSize: 30, color: COLORS.darkyellow }}
                      />
                      <StarOutlineOutlinedIcon
                        style={{ fontSize: 30, color: COLORS.darkyellow }}
                      />
                    </div>
                    <div>
                      <span style={{ color: COLORS.white }}>
                        Rate and review
                      </span>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={7}>
                  <div>
                    <textarea name="" id="" cols="50" rows="3"></textarea>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      style={{
                        background: COLORS.darkyellow,
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid> */}
            </div>
            {/*
             */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default OrderDetailPage;
