import { Button, Grid, Box } from "@mui/material";
import React from "react";

import DownloadIcon from "@mui/icons-material/Download";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { useNavigate } from "react-router-dom";
import OrderSidebar from "./components/OrderSidebar";
import { COLORS } from "../../../assets/COLORS";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
const MyOrders = () => {
  const navigate = useNavigate();
  return (
    <div style={{ padding: 10 }}>
      <Grid container spacing={2} style={{}}>
        <Grid item xs={12} sm={6} md={6} lg={3} spacing={2}>
          <OrderSidebar />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={9} spacing={2}>
          <div
            style={{ background: "white", padding: 20, borderRadius: 20 }}
            className="table-responsive"
          >
            <table className="table">
              <thead style={{ textAlign: "center" }}>
                <th>Date</th>
                <th>Order NO.</th>
                <th>Order Name</th>
                <th>Order Total</th>
                <th>Status</th>
                <th>Expired On</th>
                <th>Actions</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>23 June 2022</span>{" "}
                  </td>
                  <td>
                    <span>PK4525</span>{" "}
                  </td>
                  <td>
                    <span>telugu south style wedding psd sheets 46x26</span>{" "}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {" "}
                    <span>Rs.299 /-</span>{" "}
                  </td>

                  <td>
                    <span>Ready</span>{" "}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <span>29-10-2022 10:11 AM</span>{" "}
                  </td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <Button
                        variant="contained"
                        endIcon={<KeyboardArrowRightOutlinedIcon />}
                        size="small"
                        sx={{ mx: 1 }}
                        onClick={() => navigate("/order/1")}
                        style={{ background: COLORS.fifth }}
                      >
                        Details
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyOrders;
