import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { COLORS } from "../../assets/COLORS";

function LoginModal(props) {
  const [show, setShow] = useState(true);

  const [showOTP, setShowOTP] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={() => props.setLoginPopup(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login/Create Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!firstTime ? (
            <>
              {!showOTP ? (
                <>
                  <TextField
                    id="outlined-basic"
                    label="Mobile Number"
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                  <Button
                    variant="contained"
                    style={{
                      background: COLORS.primary,
                      width: "100%",
                      height: 50,
                      marginTop: 25,
                      marginBottom: 35,
                    }}
                    onClick={() => setShowOTP(true)}
                  >
                    Continue
                  </Button>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Please enter your OTP"
                      variant="outlined"
                      inputProps={{
                        min: 0,
                        style: {
                          textAlign: "center",
                          fontSize: 35,
                          fontWeight: "bold",
                          letterSpacing: 12,
                        },
                      }}
                      style={{ width: 250 }}
                    />
                  </div>
                  <Button
                    variant="contained"
                    style={{
                      background: COLORS.secondary,
                      width: "100%",
                      height: 50,
                      marginTop: 25,
                      marginBottom: 35,
                    }}
                  >
                    Verify
                  </Button>
                </>
              )}
            </>
          ) : (
            <div>
              <div
                style={{
                  padding: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <span>fill your details to serve you better in future</span>
              </div>

              <TextField
                id="outlined-basic"
                label="name"
                variant="outlined"
                style={{ width: "100%", marginTop: 5, marginBottom: 5 }}
              />
              <TextField
                id="outlined-basic"
                label="occupation"
                variant="outlined"
                style={{ width: "100%", marginTop: 5, marginBottom: 5 }}
              />
              <TextField
                id="outlined-basic"
                label="state"
                variant="outlined"
                style={{ width: "100%", marginTop: 5, marginBottom: 5 }}
              />
              <TextField
                id="outlined-basic"
                label="district"
                variant="outlined"
                style={{ width: "100%", marginTop: 5, marginBottom: 5 }}
              />

              <div>
                <Button
                  variant="contained"
                  style={{
                    background: COLORS.primary,
                    width: "100%",
                    height: 50,
                    marginTop: 25,
                    marginBottom: 10,
                  }}
                  onClick={() => setShowOTP(true)}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginModal;
