import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function TempLogin() {
  const navigate = useNavigate();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const loginSubmit = () => {
    if (username === "8822288871" && password === "969696") {
      localStorage.setItem("userLogin", JSON.stringify(true));
      /* navigate("/"); */
      window.location.reload();
    }
  };

  /*     const navigate = useNavigate();
    useEffect(() => {
      setTimeout(() => {
        if (logincheck === null) {
          navigate("/login");
        }
      }, 1000);
    }, []);
  
 */
  return (
    <div className="col-md-4 mx-auto">
      <div>
        <h2>Website Under Construction. Please Wait For Some Days</h2>
      </div>

      <div style={{ marginTop: 65 }}>
        <h6>Developer Login</h6>
        <div className="form-group ">
          <input
            type="text"
            name=""
            placeholder="username"
            id=""
            className="form-control"
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="form-group my-2">
          <input
            type="password"
            name=""
            placeholder="password"
            id=""
            className="form-control"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div
          className="form-group my-2"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="btn btn-primary btn-block shadow-none"
            onClick={loginSubmit}
          >
            LOGIN
          </button>
        </div>
      </div>
    </div>
  );
}

export default TempLogin;
