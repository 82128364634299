import React, { useCallback, useEffect, useState } from "react";
import { Document, pdfjs, Page } from "react-pdf";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";

import sample from "../../../assets/psd1.pdf";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import CancelIcon from "@mui/icons-material/Cancel";
import { Modal } from "react-bootstrap";
import { COLORS } from "../../../assets/COLORS";
const PSDView = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderNavButtons, setRenderNavButtons] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setRenderNavButtons(true);

    console.log("NUMBPAGES", numPages);
  }

  const changePage = (number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + number);
  };

  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  const handleKeyPress = useCallback(
    (event) => {
      console.log(`Key pressed: ${event.key}`);
      if (event.key === "ArrowRight") {
        if (pageNumber < numPages) {
          changePage(+1);
        } else {
          <></>;
        }
      }

      console.log("PAGE", pageNumber, "Numpage", numPages);
      if (event.key === "ArrowLeft") {
        if (pageNumber > 1) {
          changePage(-1);
        } else {
          <></>;
        }
      }
    },
    [pageNumber, numPages]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        centered
        size="lg"
        close
      >
        <Modal.Header closeButton>
          <Modal.Title>Fullscreen Preview</Modal.Title>
        </Modal.Header>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Document file={sample} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              width={props.width}
              height={props.height}
            />
          </Document>
        </div>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ position: "absolute", left: 10 }}>
              <span>Press keyboard arrow buttons to next, back slides</span>
            </div>

            <Button
              disabled={pageNumber <= 1}
              onClick={previousPage}
              variant="contained"
              size="small"
              startIcon={<ArrowBackIosNewIcon />}
              style={{ background: COLORS.lightgreen }}
            >
              Back
            </Button>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              {pageNumber} / {numPages}
            </div>
            <Button
              disabled={pageNumber === numPages}
              onClick={nextPage}
              variant="contained"
              size="small"
              endIcon={<ArrowForwardIosIcon />}
              style={{ background: COLORS.lightgreen }}
            >
              Next
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PSDView;
