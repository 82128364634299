import { Grid } from "@mui/material";
import React from "react";

import Image_1 from "../../../assets/images/0001.jpg";
import Image_2 from "../../../assets/images/0002.jpg";
import Image_3 from "../../../assets/images/0003.jpg";
import Image_4 from "../../../assets/images/0004.jpg";
import Image_5 from "../../../assets/images/0005.jpg";
import Image_6 from "../../../assets/images/0006.jpg";

function PSDthumb() {
  const ListImages = [
    { image_url: Image_1 },
    { image_url: Image_2 },
    { image_url: Image_3 },
    { image_url: Image_4 },
    { image_url: Image_5 },
    { image_url: Image_6 },
    { image_url: Image_3 },
    { image_url: Image_4 },
    { image_url: Image_5 },
    { image_url: Image_6 },
  ];
  return (
    <div style={{ backgroundColor: "white" }}>
      <Grid container spacing={2} xs={12} sm={12} md={12} lg={12}>
        {ListImages.map((sri, i) => (
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div
              style={{
                width: 250,
                margin: 10,
              }}
            >
              <img src={sri.image_url} height="auto" width="100%" />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default PSDthumb;
