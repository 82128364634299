import React from "react";
import { COLORS } from "../assets/COLORS";
import { Box, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";

function FooterBar() {
  return (
    <div style={{ background: COLORS.darkblue2, padding: 20 }}>
      <Grid
        container
        spacing={0}
        style={{
          padding: 10,
        }}
      >
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div>
            <div>
              <h2 style={{ color: COLORS.darkyellow }}>PSDKART.COM</h2>
              <h6 style={{ color: COLORS.white }}>
                psdkart is a digital albums selling site
              </h6>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} style={{ color: COLORS.white }}>
          <div>
            <div>
              <h5 style={{ color: COLORS.darkyellow }}>Useful Links</h5>
              <ul>
                <li>
                  <Link
                    style={{ textDecoration: "none", color: COLORS.white }}
                    to="/"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ textDecoration: "none", color: COLORS.white }}
                    to="/"
                  >
                    Terms and Conditions{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ textDecoration: "none", color: COLORS.white }}
                    to="/"
                  >
                    About us{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} style={{ color: COLORS.white }}>
          <div>
            <div>
              <h5 style={{ color: COLORS.darkyellow }}>Contact us</h5>
              <span>Mobile : 8822288871</span>
              <br />
              <span>Email : admin@psdkart.com</span>
            </div>
          </div>
        </Grid>
      </Grid>

      <div style={{ color: COLORS.white, borderTop: "1px solid white" }}>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <span>All rights reserved @ psdkart.com</span>
        </div>
      </div>
    </div>
  );
}

export default FooterBar;
