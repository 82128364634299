import React from "react";
import FooterBar from "../../components/FooterBar";
import LatestPSD from "./components/LatestPSD";

const HomePage = () => {
  return (
    <div>
      <div>
        <LatestPSD />

        <FooterBar />
      </div>
    </div>
  );
};

export default HomePage;
