import React from "react";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import BoltIcon from "@mui/icons-material/Bolt";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const LatestPSD = () => {
  let navigate = useNavigate();
  const PSDList = [
    {
      id: 1,
      price: 200,
      category: "wedding",
      size: "12GB",
      instantdownload: "true",
      image_size: "12X36",

      feature_img_url:
        "https://www.freepsdking.com/wp-content/uploads/2022/07/Latest-Wedding-Album-Design-2019-Free-Download-Freepsdking.com-2-1-1024x536.jpg",
    },

    {
      id: 2,
      price: 200,
      category: "wedding",
      size: "12GB",
      image_size: "12X36",
      instantdownload: "true",
      feature_img_url:
        "https://1.bp.blogspot.com/-jl65CSoiKNw/YScMUlsHWfI/AAAAAAAAbzU/ZybARxMiSOEoz-Za2T2vCTbP6787PaXEQCLcBGAsYHQ/w400-h268/wedding-banner-psd-free-online.PNG",
    },
    {
      id: 3,
      price: 200,
      category: "wedding",
      size: "12GB",
      image_size: "12X36",
      instantdownload: "true",
      feature_img_url:
        "https://i0.wp.com/freeweddingpsd.com/wp-content/uploads/2020/wb/12x36_-(854).webp?ssl=1",
    },
    {
      id: 4,
      price: 200,
      category: "wedding",
      size: "12GB",
      image_size: "12X36",
      instantdownload: "true",
      feature_img_url:
        "https://albumcart.com/wp-content/uploads/edd/2020/06/Wedding-Pack-Banner-1-1536x614.jpg",
    },
    {
      id: 5,
      price: 200,
      category: "wedding",
      size: "12GB",
      instantdownload: "true",
      image_size: "12X36",

      feature_img_url:
        "https://www.freepsdking.com/wp-content/uploads/2022/07/Latest-Wedding-Album-Design-2019-Free-Download-Freepsdking.com-2-1-1024x536.jpg",
    },

    {
      id: 6,
      price: 200,
      category: "wedding",
      size: "12GB",
      image_size: "12X36",
      instantdownload: "true",
      feature_img_url:
        "https://1.bp.blogspot.com/-jl65CSoiKNw/YScMUlsHWfI/AAAAAAAAbzU/ZybARxMiSOEoz-Za2T2vCTbP6787PaXEQCLcBGAsYHQ/w400-h268/wedding-banner-psd-free-online.PNG",
    },
    {
      id: 7,
      price: 200,
      category: "wedding",
      size: "12GB",
      image_size: "12X36",
      instantdownload: "true",
      feature_img_url:
        "https://i0.wp.com/freeweddingpsd.com/wp-content/uploads/2020/wb/12x36_-(854).webp?ssl=1",
    },
    {
      id: 8,
      price: 200,
      category: "wedding",
      size: "12GB",
      image_size: "12X36",
      instantdownload: "true",
      feature_img_url:
        "https://albumcart.com/wp-content/uploads/edd/2020/06/Wedding-Pack-Banner-1-1536x614.jpg",
    },
    {
      id: 9,
      price: 200,
      category: "wedding",
      size: "12GB",
      instantdownload: "true",
      image_size: "12X36",

      feature_img_url:
        "https://www.freepsdking.com/wp-content/uploads/2022/07/Latest-Wedding-Album-Design-2019-Free-Download-Freepsdking.com-2-1-1024x536.jpg",
    },

    {
      id: 10,
      price: 200,
      category: "wedding",
      size: "12GB",
      image_size: "12X36",
      instantdownload: "true",
      feature_img_url:
        "https://1.bp.blogspot.com/-jl65CSoiKNw/YScMUlsHWfI/AAAAAAAAbzU/ZybARxMiSOEoz-Za2T2vCTbP6787PaXEQCLcBGAsYHQ/w400-h268/wedding-banner-psd-free-online.PNG",
    },
    {
      id: 11,
      price: 200,
      category: "wedding",
      size: "12GB",
      image_size: "12X36",
      instantdownload: "true",
      feature_img_url:
        "https://i0.wp.com/freeweddingpsd.com/wp-content/uploads/2020/wb/12x36_-(854).webp?ssl=1",
    },
    {
      id: 12,
      price: 200,
      category: "wedding",
      size: "12GB",
      image_size: "12X36",
      instantdownload: "true",
      feature_img_url:
        "https://albumcart.com/wp-content/uploads/edd/2020/06/Wedding-Pack-Banner-1-1536x614.jpg",
    },
  ];

  return (
    <div style={{ marginTop: "0px" }}>
      <Box sx={{ flex: 1 }}>
        <Grid container>
          {PSDList.map((val, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              key={index}
              style={{
                margin: 0,
              }}
            >
              <div style={{ margin: 10 }}>
                <Card
                  elevation={4}
                  style={{
                    cursor: "pointer",
                    /*              width: "90%", */
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  onClick={() => navigate(`/product/${val.id}`)}
                >
                  <div
                    style={{
                      position: "absolute",
                      padding: 5,
                      background: "#000000a8",
                    }}
                  >
                    <div>
                      <span style={{ color: "white" }}>{val.image_size}</span>
                    </div>
                  </div>

                  <div
                    style={{
                      background: "linear-gradient(29deg, #bb0121, #522c96)",
                      /*            background: "black", */
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={val.feature_img_url}
                        width="auto"
                        height="190px"
                      />
                    </Box>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 10,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",

                        display: "flex",
                        justifyContent: "end",
                        alignItems: "start",

                        marginTop: "-40px",
                        background: "#000000a8",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "15px",
                      }}
                    >
                      <span style={{ color: "white" }}> {val.category}</span>
                    </div>
                    <div>
                      <span style={{ color: "#009688", fontSize: 20 }}>
                        Code : {val.id}
                      </span>
                    </div>
                    <div>
                      <span style={{ fontSize: 20, color: "#009688" }}>
                        Price : {val.price}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "baseline",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#e91e63",

                          paddingTop: 2,
                          paddingBottom: 2,
                          borderRadius: "7px",
                          fontWeight: "bold",
                          fontSize: "13px",
                        }}
                      >
                        <BoltIcon /> Instant Download
                      </span>
                    </div>
                  </div>
                </Card>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
          marginBottom: 30,
        }}
      >
        <Stack spacing={2}>
          <Pagination count={10} variant="outlined" color="primary" />
        </Stack>
      </div>
    </div>
  );
};

export default LatestPSD;
