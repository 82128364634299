import React, { useState } from "react";
import { Document, pdfjs, Page } from "react-pdf";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";

import sample from "../../../assets/psd1.pdf";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import CancelIcon from "@mui/icons-material/Cancel";
import { Modal } from "react-bootstrap";
import { COLORS } from "../../../assets/COLORS";
const PSDViewMobile = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderNavButtons, setRenderNavButtons] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setRenderNavButtons(true);

    console.log("NUMBPAGES", numPages);
  }

  const changePage = (number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + number);
  };

  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  return (
    <div>
      <Modal
        show={props.thumbshow}
        onHide={props.handleThumbClose}
        centered
        /*         size="sm" */
        close
      >
        <Modal.Header closeButton>
          <Modal.Title>Fullscreen Preview</Modal.Title>
        </Modal.Header>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 25,
            paddingBottom: 25,
          }}
        >
          <Document file={sample} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              width={props.width}
              height={props.height}
            />
          </Document>
        </div>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              disabled={pageNumber <= 1}
              onClick={previousPage}
              variant="contained"
              size="small"
              startIcon={<ArrowBackIosNewIcon />}
              style={{ background: COLORS.lightgreen }}
            >
              Back
            </Button>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              {pageNumber} / {numPages}
            </div>
            <Button
              disabled={pageNumber === numPages}
              onClick={nextPage}
              variant="contained"
              size="small"
              endIcon={<ArrowForwardIosIcon />}
              style={{ background: COLORS.lightgreen }}
            >
              Next
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PSDViewMobile;
