export const COLORS = {
  /*   blue: "#99d4e6",
  darkBlue: "#14213d",
  purple: "#B79AC7",
  lightPurple: "#B699C5",
  darkPurple: "#A782BC",
  orange: "#FFCE6F",
  darkYellow: "#FFC24D",
  pink: "#E69999",
  darkPink: "#DE7F7F",
  text: "#414141",
  black: "#000000",
  white: "#ffffff",
  grey: "#808080",
  shipGrey: "#575756",
  dimGrey: "#707070",
  lightGrey: "#D3D3D3",
  grey99: "#FCFCFC",
  red: "#FF0000",
  green: "#6FC176",
  lightPink: "#FFEDED", */

  primary: "#264653",
  secondary: "#2A9D8F",
  third: "#E9C46A",
  fourth: "#F4A261",
  fifth: "#E76F51",
  lightgreen: "#52b788",
  lightblue: "#a9def9",
  lightteal: "#489fb5",
  darkpurple: "#461A5A",
  white: "#fff",
  lightgrey: "#6c757d",
  darkblue: "#466AB2",
  darkyellow: "#FFB800",
  darkblue2: "#172337",
  darkpurple: "#432C7A",
};
