import React from "react";
import { Button, Box } from "@mui/material";

function OrderSidebar() {
  return (
    <div style={{ background: "white", padding: 20, borderRadius: 20 }}>
      <h4 style={{ textAlign: "center", color: "grey" }}>My Account</h4>

      <Box sx={{ my: 2 }}>
        <Button variant="outlined" sx={{ width: "100%" }}>
          My Orders
        </Button>
      </Box>

      <Box sx={{ my: 2 }}>
        <Button variant="outlined" sx={{ width: "100%" }}>
          My Profile
        </Button>
      </Box>
    </div>
  );
}

export default OrderSidebar;
