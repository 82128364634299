import logo from "./logo.svg";
/* import "./App.css"; */
import HomePage from "./pages/Homepage/HomePage";
import HeaderBar from "./components/HeaderBar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import SingleProduct from "./pages/singleproduct/SingleProduct";
import TempLogin from "./pages/TempLogin";
import React, { useEffect } from "react";
import MyOrders from "./pages/myaccount/orders/MyOrders";
import PSDView from "./pages/singleproduct/components/PSDView";
import OrderDetailPage from "./pages/myaccount/orders/OrderDetailPage";
import FooterBar from "./components/FooterBar";
import { COLORS } from "./assets/COLORS";

function App() {
  /*   */
  const logincheck = JSON.parse(localStorage.getItem("userLogin"));
  console.log("login", logincheck);

  return (
    <div>
      <Router>
        {logincheck && <HeaderBar />}
        <div
          className="container-fluid"
          style={{ height: "100vh", background: COLORS.darkblue2 }}
        >
          {logincheck ? (
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/product/:id" element={<SingleProduct />} />
              <Route path="/myaccount" element={<MyOrders />} />
              <Route path="/product/:id/psdpreview" element={<PSDView />} />
              <Route path="/order/:id" element={<OrderDetailPage />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<TempLogin />} />
            </Routes>
          )}
        </div>
      </Router>
    </div>
  );
}

export default App;
